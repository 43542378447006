@import "../../../../../variables.scss";

.createHospitalHeading {
  margin-bottom: 10px;
  .muted {
    color: $muted;
  }
  .lessthan {
    font-size: 20px;
    transform: scale(0.5, 1);
    padding: 0 7px;
  }
}

.devicePreview {
  border: 2px dashed rgba(154, 154, 167, 0.28);
  margin-top: 10px;
  background: #222222;
  padding: 7px 20px !important;
  span {
    color: $dropdown-text;
    font-weight: 600;
    &.heading {
      // color: $dropdown-text;
      font-weight: 200;
    }
  }
}
