body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "DINPro-Regular";
  src: url("./fonts/DINPro-Regular.otf") format("opentype");
}
@font-face {
  font-family: "DINPro-Medium";
  src: url("./fonts/DINPro-Medium.otf") format("opentype");
}
@font-face {
  font-family: "DINPro-Light";
  src: url("./fonts/DINPro-Light.otf") format("opentype");
}
@font-face {
  font-family: "DINPro-Bold";
  src: url("./fonts/DINPro-Bold.otf") format("opentype");
}
@font-face {
  font-family: "DINPro-Black";
  src: url("./fonts/DINPro-Black.otf") format("opentype");
}
@font-face {
  font-family: "HelveticaNeue-Regular";
  src: url("./fonts/HelveticaNeue-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue-Medium";
  src: url("./fonts/HelveticaNeue-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue-Light";
  src: url("./fonts/HelveticaNeue-Light.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url("./fonts/HelveticaNeue-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue-Thin";
  src: url("./fonts/HelveticaNeue-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue-Italic";
  src: url("./fonts/HelveticaNeue-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue-Heavy";
  src: url("./fonts/HelveticaNeue-Heavy.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue-Black";
  src: url("./fonts/HelveticaNeue-BlackCond.ttf") format("truetype");
}

.fontHelveticaNeueRegular {
  font-family: "HelveticaNeue-Regular";
}
.fontHelveticaNeueMedium {
  font-family: "HelveticaNeue-Medium";
}
.fontDINProRegular {
  font-family: "DINPro-Regular";
}
.pointer {
  cursor: pointer;
}

.textNoWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.textEllipses {
  span {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.spinnerRight {
  padding-left: 4px;
}

.dashboardManagementRoute {
  // width: 159px;
  display: flex;
  padding: 1px 1px;
  // margin-top: 22px;
}
// .dashboardRoute {
//   padding: 32px 32px;
// }
.dashboardRoute {
  padding: 32px 26px;
  // margin-top: 55px;
}
.width-100 {
  width: 100%;
}
// .ms-Callout-main {
//   width: 133px;
// }

// .ms-TextField-fieldGroup,
// .ms-Dropdown-title,
// .css-b62m3t-container {
//   width: 317px;
// }
// .except {
//   .ms-TextField-fieldGroup,
//   .ms-Dropdown-title,
//   .css-b62m3t-container {
//     width: unset !important;
//   }
// }
.ms-StackItem {
  .ms-TextField-fieldGroup,
  .ms-Dropdown-title,
  .css-b62m3t-container {
    border: 1px solid rgba(191, 195, 196, 0.4);
    box-sizing: border-box;
    // color: white;
  }
  // .css-3iigni-container {
  // margin-bottom: 10px;
  // }
  .addl-class__control {
    background-color: rgb(21, 21, 21);
    border-color: rgb(21, 21, 21);
    height: 30px;
    min-height: 33px;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      height: 30px;
      min-height: 33px;
    }
  }
  .addl-class__menu-list {
    background-color: rgb(21, 21, 21);
    color: #ffffff;
  }
  .addl-class__option {
    &:hover,
    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      background-color: #0fb3ff;
      border-color: #333333;
    }
  }
  .css-1okebmr-indicatorSeparator {
    -webkit-align-self: unset;
    align-self: unset;
  }
  .css-1f43avz-a11yText-A11yText {
    padding: 5px;
  }
  .css-14el2xx-placeholder {
    margin-bottom: 3px;
  }
  .css-qc6sy-singleValue {
    color: #ffffff;
  }
  .css-319lph-ValueContainer {
    padding: 2px 5px;
  }
}

.circleNumber {
  text-transform: capitalize;
}
.deleteModal {
  background-color: #7e7e8180;
  z-index: 11;
  .smallModalStyle {
    min-height: 144px;
    min-width: 32%;
    .deleteSection {
      padding: 10px;
      .subHeading {
        span {
          color: #7e7e81;
        }
        margin: 15px 0;
      }
      .btnCancel {
        margin-left: 5px;
      }
    }
  }
}
.deleteModal.z-index {
  z-index: 9999999;
}
.consenterrorMessage {
  margin-top: 0 !important;
}

.float-right {
  float: right !important;
}

.width-20 {
  width: 20%;
}
@media (min-width: 640px) {
  .ml-9 {
    margin-left: 9% !important;
  }
}

.displayFlex {
  display: flex;
}

.ms-mt-5 {
  margin-top: 20px;
}

.ms-mt-40 {
  margin-top: 40px;
}

.ms-mt-25 {
  margin-top: 75px;
}

.ms-mb-20 {
  margin-bottom: 19px;
}

.ms-mb-10 {
  margin-bottom: 10px;
}

.css-1pndypt-Input {
  color: #ffffffde !important;
}

.dropdownDownload {
  background-color: #121212;
  padding: 10px;
  position: absolute;
  z-index: 1;
}
.activitydatelabel {
  margin-bottom: 6px;
}
.sortOptionsDropdownHeading {
  padding: 10px 0px;
  color: #7e7e81;
}

.btnDropdownWidth {
  width: 96%;
  margin-left: 12px;
  margin-top: 10px;
}

.ms-Callout-main {
  background-color: #121212;
}

.ms-Button::after {
  border-left: unset;
}

.react-datepicker__header {
  text-align: center !important;
  background-color: #121212 !important;
  border-bottom: 0px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding-top: 8px !important;
  position: relative !important;
}
.react-datepicker__current-month {
  color: #ffffff !important;
}
.react-datepicker__today-button {
  background: #3e82cf !important;
  border-top: 0px !important;
  cursor: pointer !important;
  text-align: center !important;
  font-weight: bold !important;
  padding: 5px 0 !important;
  clear: left !important;
}

.react-datepicker {
  background-color: #121212 !important;
  color: #fff !important;
  border: 1px solid #242526 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #fff !important;
}

// .ms-Callout {
//   width: auto;
//   min-width: 20%;
//   max-height: 300px !important;
// }

.ms-Dropdown-callout {
  max-height: 315px !important;
}

.text-white {
  color: rgb(208, 208, 208);
}

.userProfile {
  padding-left: 10px;
  font-size: 18px;
  color: #ffffffde;
  text-transform: capitalize;
}

.userRole {
  padding-left: 10px;
  font-size: 12px;
}
.imageEdit {
  position: relative;
  padding-right: 2px;
}
.imageEdit:after {
  font-weight: bolder;
  content: "\00270E";
  position: absolute;
  top: 24px;
  left: 30px;
  color: #e89b0c;
  align-items: center;
  display: flex;
  justify-content: center;
}

.download_example_page {
  position: fixed;
  bottom: 10%;
  width: 300px;
}
.region_devices {
  margin-right: 3%;
  width: 8%;
}
.fs-small {
  font-weight: 500;
  font-size: 12px;
  color: #7e7e81;
}
.fs-medium {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #c0c3c4;
}
.pos-rel {
  position: relative;
}
.border-none {
  border: none !important;
}
.log-checkbox {
  .ms-Checkbox-checkbox {
    width: 14px;
    height: 14px;
  }
}
.h-100 {
  height: 100%;
}
.addl-class__option--is-focused {
  background-color: #0fb3ff !important;
}
.justify-space-between {
  display: flex !important;
  justify-content: space-between;
}
.recharts-tooltip-cursor {
  fill: #101112 !important;
}

.multiRoleDialog {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
  background-color: #000000;
  color: #d7d7d7;

  .roleDialogheader {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
  }

  .subheader {
    font-size: 16px;
  }

  .boxHeader {
    display: flex;
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #000000;
    margin: 36px;

    .firstBox {
      background: linear-gradient(107.53deg, #f9a3de 5.72%, #ffd578 100.37%);
      width: 199px;
      height: 199px;
      border-radius: 6px;
      margin: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .secondBox {
      background: #d7d7d7;
      width: 199px;
      height: 199px;
      border-radius: 6px;
      margin: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .boxText {
      margin-bottom: 16px;
    }
  }

  .continue {
    background: #01b1ff;
    border-radius: 6px;
    width: 268px;
    height: 50px;
    margin: 24px;
  }
  .boxCheck {
    float: right;
    // left: 24%;
    margin-right: 10%;
    top: -63%;
    position: relative;
    // top: 30%;
  }
  .multi {
    padding: 20px;
  }
}
.ChangePassword {
  color: red;
}
#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
#form-file-upload {
  height: 15rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

.ms-TextField-suffix1 {
  background-color: transparent !important;
  color: white !important;
}
.all-videos-card-width {
  width: 21.6% !important;
  max-width: 22% !important;
  padding: 10px !important;
}
.dashboardRoute > .no-root-padding-required {
  padding: 0 !important;
}

// .loader .loaderBox {
//   position: absolute;
//   top: 35% !important;
//   left: 45.7% !important;
//   transform: translate(-50%, -50%);
//   z-index: 111;
// }
.loader {
  position: static !important;
  min-height: 0px !important;
  min-width: 0px !important;
}

.loader .loaderBox {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 111;
}
.point-events-none {
  pointer-events: none !important;
}
.mute-volume img:first-child {
  visibility: hidden;
}

// .main-content-overflow{
//   overflow: auto !important;
// }

@media (max-width: 1024px) {
  .main-content-overflow {
    overflow: auto !important;
  }
}

#datasetpage {
  height: 840px;
}

.custm-select-box-video-page {
  position: absolute;
  right: 118px;
  top: -24px;
  cursor: pointer;
  height: 25px;
  background: transparent;
  padding: 2px;
  box-shadow: none;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  border-color: rgb(208, 208, 208);
  width: 155px;
}
