@import "../../../variables.scss";

.logsSection {
  // margin: -31px -31px 0 -31px !important;
  .float-right {
    float: right;
  }
}
.logsView {
  .userCreate {
    margin: 0 5rem;
    padding: 0;
  }
  .logsViewSection {
    margin-top: 12px;
    background-color: #c5c5c5;
    color: #000000;
    .logsViewData {
      overflow: scroll;
      height: calc(100vh - 200px);
      text-overflow: clip;
    }
  }
}
.recharts-tooltip-wrapper {
  text-transform: capitalize;
  .recharts-default-tooltip {
    background-color: $theme-dashboard !important;
    .recharts-tooltip-item {
      color: #ffffff !important;
    }
  }
}

.chartToolTip {
  width: 300px !important;
  max-width: 300px !important;
  height: auto !important;
  .toolTipContent {
    margin-left: 4px;
  }
}

.salesDashboard,
.serviceDashboard {
  // .addl-class__menu {
  //   width: 148px !important;
  // }
  tspan {
    font-size: 9px;
    text-transform: capitalize;
  }
  .totalSection {
    background: $total-section-bg;
    padding: 16px;
    text-align: right;
    padding-right: 120px;
    span {
      font-size: 20px;
      padding-left: 20px;
      color: $dropdown-text;
      font-weight: 600;
    }
  }
  .filterSection {
    padding: 10px;
    background-color: $theme-dashboard;
    .label {
      padding-bottom: 8px;
    }
    span {
      color: $light-text-color;
    }
    .css-1gtu0rj-indicatorContainer,
    .css-tlfecz-indicatorContainer {
      padding: 0px;
    }
  }
  .chartSection {
    margin-top: 8px;
    .ms-SearchBox {
      background-color: $total-section-bg;
    }
  }
  .bgColor {
    background-color: $theme-dashboard;
    margin-right: -8px;
    padding: 12px;
  }
  .start {
    margin-left: -8px;
  }
  .procedureDuration,
  .overlayMode {
    height: 260px;
    // .centerLabel {
    //   //   tspan {
    //   //     color: $dropdown-text;
    //   //     font-size: 10px;
    //   //   }
    // }
    .colorCircleMain {
      text {
        padding-left: 20px;
        font-size: 10px;
        text-transform: capitalize;
      }
    }
  }
  .workflow {
    height: 164px;
    margin-top: 8px;
    span {
      color: $dropdown-text;
    }
    .number {
      font-size: 22px;
    }
    .ms-Stack {
      padding-top: 30px;
    }
  }
  .colorCircleMain {
    padding: 0;
    margin: 0 0 5px 0;
    span {
      padding-left: 20px;
      font-size: 10px;
      width: 64%;
    }
    .text {
      color: $dropdown-text;
      text-transform: capitalize;
    }
    .colorCircle {
      height: 8px;
      width: 8px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .map {
    // height: 456px;
    overflow: hidden;
  }
  .bottomSection {
    margin-top: 8px;
    .btmHeight {
      height: 230px;
    }
    span {
      color: $dropdown-text;
    }
    .number {
      font-size: 28px;
    }
  }
  .heading {
    color: $wizard-text !important;
  }
  .placement {
    margin-top: 25px;
  }
  .mt-15 {
    margin-top: 15px;
  }
  .squareBoxMain {
    .ms-StackItem {
      margin-right: 6px;
      display: flex;
      align-items: center;
    }
    .squareBox {
      height: 8px;
      width: 8px;
      display: inline-block;
      margin-right: 1px;
      &.utilized {
        background-color: #7e7e81;
      }
      &.available {
        background-color: #8ac8fe;
      }
    }
  }
}
.downloadBtn {
  cursor: pointer;
  .ms-Button--commandBar {
    background-color: transparent;
  }
  span {
    color: $active-color;
    // background-color: transparent !important;
    margin: 0;
    &.ms-Button-label {
      color: $active-color;
    }
    &.download {
      color: $active-color;
      font-weight: 600;
    }
  }
}
.downloadSection {
  padding-top: 10px;
}
.viewLogFiles {
  &.deviceManagement {
    padding: 0;
    width: auto;
  }
  .totalSection {
    padding-bottom: 20px;
    padding-top: 20px;
    margin: -8px;
  }
}

@media (min-width: 640px) {
  .salesDashboard,
  .dashboardRoute,
  .logsView {
    .ms-lg1-point-5 {
      width: 12.5%;
    }
  }
}
.inheritHeight {
  height: 180px;
  padding-top: 30px;
}
.inheritHeight-specialties {
  height: 180px;
  padding-top: 35px;
}

.height-150 {
  height: 150px;
}
.flex-center {
  display: flex !important;
  align-items: center;
}

.recharts-legend-wrapper {
  display: none;
}

.text-center {
  text-align: center;
}

.activitySearch {
  background-color: #000000;
  width: 120px !important;
  margin-right: 30px !important;
  border-width: 0px !important;
  margin-left: 20px !important;
}
.activitySearch:hover {
  .ms-SearchBox-iconContainer {
    color: unset;
    border-color: unset;
  }
}

.searchBoxDownloadSection {
  margin-top: 20px;
  margin-right: 16px;
}

.textNoWrap {
  abbr {
    text-decoration: none !important;
  }
}

.activitySectionMain {
  .css-b62m3t-container {
    border: 1px solid rgba(191, 195, 196, 0.4);
    box-sizing: border-box;
    color: #7e7e81;
    margin-top: 5px;
  }
  .css-3iigni-container {
    // margin-bottom: 10px;
    margin-top: 5px;
  }
}
.ml-12 {
  margin-left: 12px;
}
.custom-width-filter-log {
  width: 160px;
  max-width: 160px;
}
@media screen and (max-width: 400px) {
  .custom-width-filter-log {
    width: 160px;
    max-width: 160px;
  }
}
@media screen and (max-width: 768px) {
  .custom-width-filter-log {
    width: 160px;
    max-width: 160px;
  }
}
@media screen and (max-width: 1024px) {
  .custom-width-filter-log {
    width: 160px;
    max-width: 160px;
  }
}
@media screen and (max-width: 1200px) {
  .custom-width-filter-log {
    width: 160px;
    max-width: 160px;
  }
}
@media screen and (min-width: 1201px) {
  .custom-width-filter-log {
    width: 138px;
    max-width: 138px;
    padding: 0;
    margin: 0px 3px 0px 3px;
  }
}
