@import "../../../../variables.scss";

.deviceManagement,
.management {
  padding: 30px 0 0 208px;
  width: 88%;
  .heading {
    span {
      font-size: 24px;
      font-family: DINPro-Regular;
    }
    padding-bottom: 30px;
  }
  .addDevice {
    padding-bottom: 20px;
    .ms-Button {
      background-color: $active-color;
      border: 1px solid $active-color;
      color: $theme-dashboard;
      font-weight: 600;
      // .ms-Icon {
      // }
    }
  }

  .tableHeading {
    background-color: $theme-dashboard;
    padding: 6px 0px;
    span {
      color: $wizard-text;
    }
  }
  .tableText {
    background-color: #1f1f1f;
    padding: 4px 0px;
    margin-bottom: 1px;
    cursor: pointer !important;
    .ms-StackItem {
      height: auto;
      display: flex;
      align-items: center;
    }
    span {
      color: $dropdown-text;
    }
  }
  .availableDeviceList {
    .deviceSubHeading {
      padding-bottom: 8px;
      span {
        font-size: 20;
        font-family: DINPro-Medium;
        font-weight: 400 !important;
      }
    }
    padding-top: 20px;
    border-top: 1px solid #343434;
  }
}

// -------------deviceprofile------------------
.device-profile-header {
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin-left: 55px;

  font-family: "D-DIN-PRO";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #d7d7d7;
}

.device-profile-container-left {
  box-sizing: border-box;

  position: absolute;
  width: 400px;
  height: 350px;
  left: 352px;
  top: 133px;
  background: #101112;
}
.device-profile-continer-right {
  box-sizing: border-box;
  position: absolute;
  width: 482px;
  height: 415px;
  left: 770px;
  top: 108px;
}
.device-profile-container {
  display: flex;
  color: #7e7e81;
  padding: 30px;
}
.device-profile-container-left {
  border-left: 3px solid;
  border-left: 3px solid;
  border-image: linear-gradient(#0051b0, #fbaffc) 0 100%;
}
.device-profile-left {
  width: 50%;
  padding: 25px;
}
.device-profile-right {
  width: 50%;
  padding: 25px;
}
.device-item {
  margin-bottom: 26px;
}
.device-img-continer {
  width: 400px;
  height: 400px;
  position: absolute;
}
.device-img {
  position: absolute;
  z-index: 10;
  width: 200px;
  height: 250.71px;
  transform: translate(40%, 40%);
}
@media (min-width: 640px) {
  .ms-lg1-point-5 {
    width: 13.33%;
  }
}
