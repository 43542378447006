.pageClassName {
  display: inline-block;
  background-color: #1d1d1d;
  margin: 0 2px;
  cursor: pointer;
  margin: "0 1px";
}

.breakClassName {
  display: inline-block;
  color: #01b1ff;
  cursor: pointer;
}

.breakLinkClassName {
  padding-left: 15px;
  padding-right: 15px;
}

.breakLinkClassName:focus {
  outline: none;
  box-shadow: none;
}

.previousClassName {
  display: inline-block;
  color: #01b1ff;
  cursor: pointer;
  font-size: medium;
  background: transparent !important;
}

.previousLinkClassName {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.previousLinkClassNameDisable {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  color: #adadae;
}

.previousLinkClassNameDisable:focus {
  outline: none;
  box-shadow: none;
}

.previousLinkClassName:focus {
  outline: none;
  box-shadow: none;
}

.previousLinkClassName:hover {
  color: #97d2eb;
}

.nextClassName {
  display: inline-block;
  color: #01b1ff;
  cursor: pointer;
  border: none;
  font-size: medium;
  background: transparent !important;
}

.nextLinkClassName {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.nextLinkClassNameDisable {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  color: #adadae;
}

.nextLinkClassNameDisable:focus {
  outline: none;
  box-shadow: none;
}

.nextLinkClassName:focus {
  outline: none;
  box-shadow: none;
}

.nextLinkClassName:hover {
  color: #97d2eb;
}

.activeClassName {
  border: 1px solid #01b1ff;
}

.pageLinkClassName {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.pageLinkClassName:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
