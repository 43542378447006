@import "../../../variables.scss";

.logsSection {
  // margin: -31px -31px 0 -31px !important;
}
// .salesDashboard {
//   padding: 25px;
// }
.serviceDashboard {
  .totalSection {
    background: $total-section-bg;
    padding: 16px;
    text-align: right;
    padding-right: 120px;
    span {
      font-size: 20px;
      padding-left: 20px;
      color: $dropdown-text;
      font-weight: 600;
    }
  }
  .filterSection {
    padding: 10px;
    background-color: $theme-dashboard;
    .label {
      padding-bottom: 8px;
    }
    span {
      color: $light-text-color;
    }
  }
  .chartSection {
    margin-top: 8px;
    .ms-SearchBox {
      background-color: $total-section-bg;
    }
  }
  .bgColor {
    background-color: $theme-dashboard;
    margin-right: -8px;
    padding: 12px;
  }
  .start {
    margin-left: -8px;
  }
  .procedureDuration,
  .overlayMode {
    height: 260px;
    // .centerLabel {
    //   tspan {
    //     color: $dropdown-text;
    //     font-size: 10px;
    //   }
    // }
    .colorCircleMain {
      text {
        padding-left: 20px;
        font-size: 10px;
        text-transform: capitalize;
      }
    }
  }
  .workflow {
    // height: 218px;
    margin-top: 8px;
    span {
      color: $dropdown-text;
    }
    .number {
      font-size: 22px;
    }
    .ms-Stack {
      padding-top: 30px;
    }
  }
  .colorCircleMain {
    padding: 0;
    margin: 0 0 5px 0;
    span {
      padding-left: 20px;
      font-size: 10px;
      width: 64%;
    }
    .text {
      color: $dropdown-text;
      text-transform: capitalize;
    }
    .colorCircle {
      height: 8px;
      width: 8px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
      // margin-bottom: 12px;
    }
  }

  .map {
    height: 456px;
  }
  .bottomSection {
    margin-top: 8px;
    .btmHeight {
      height: 230px;
    }
    span {
      color: $dropdown-text;
    }
    .number {
      font-size: 50px;
    }
  }
  .heading {
    color: $wizard-text !important;
  }
  .placement {
    margin-top: 25px;
  }
  .mt-15 {
    margin-top: 15px;
  }
  .squareBoxMain {
    .ms-StackItem {
      margin-right: 6px;
    }
    .squareBox {
      height: 8px;
      width: 8px;
      display: inline-block;
      margin-right: 1px;
      &.utilized {
        background-color: #7e7e81;
      }
      &.available {
        background-color: #8ac8fe;
      }
    }
  }
  .grid-height {
    height: 250px;
  }
}

@media (min-width: 640px) {
  .salesDashboard,
  .dashboardRoute {
    .ms-lg1-point-5 {
      width: 12.5%;
    }
    .ms-lg13-point-5 {
      width: 13.5%;
      margin-right: 20px;
      .react-datepicker__close-icon {
        right: -33px;
      }
    }
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          width: 100%;
          background-color: #151515;
          border: 1px solid rgba(191, 195, 196, 0.4);
          color: #ffffff;
          height: 29px;
        }
      }
    }
  }
}
.inheritHeight {
  height: 180px;
  padding-top: 70px;
}

.height-150 {
  height: 150px;
}
.flex-center {
  display: flex !important;
  align-items: center;
}

.recharts-legend-wrapper {
  display: none;
}

.text-center {
  text-align: center;
}

.loader {
  min-height: 100%;
  min-width: 100%;
  // background-color: #7e7e8180;
  z-index: 1111;
  position: absolute;
  .loaderBox {
    position: absolute;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%);
    z-index: 111;
  }
}

// .no-data-img {
//   width: 90%;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   color: #7e7e81;
// }
