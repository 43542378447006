@import "../../../../variables.scss";

.roleNameSection {
  padding: 30px 0 10px 0;
  text-align: center;
  span {
    color: $dropdown-text;
  }
  .roleName {
    width: 178px;
    height: 130px;
    background: linear-gradient(
      180deg,
      #313131 2.69%,
      rgba(16, 17, 18, 1) 100%
    );
    border-radius: 4px;
    margin-top: 8px;
    svg {
      height: 36px;
      width: 36px;
      padding-bottom: 10px;
    }
  }
}

// .permissionGroupSection {
//   .algorithmSection {
//     margin: 10px 0px 4px 0px;
//     .ms-Checkbox-text {
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       width: 180px;
//     }
//   }
// }

.noteMsg {
  font-size: smaller !important;
}

.surgeonFacility {
  .addl-class__value-container {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    // height: inherit;
    // display: inline-block !important;
    color: white;
    span:not(:first-child) {
      display: none;
    }
  }
  .selectioncolor {
    color: white !important;
  }
}
