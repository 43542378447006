.video-js {
  width: 100%;
}
#video-container .full-screen {
  height: 100%;
}
.full-screen .vjs-theme-city {
  height: 100% !important;
}

.vjs-theme-city .vjs-control-bar {
  min-height: 55px !important;
  height: 55px !important;
  z-index: 9;
}

.vjs-playback-rate {
  position: absolute !important;
  right: 60px !important;
  margin-top: 10px !important;
}

.vjs-play-control {
  cursor: pointer;
  left: -55px;
  //right: 4% !important;
}

.vjs-fullscreen-control {
  visibility: hidden;
}

.additional-stuff {
  position: relative;
  z-index: 9;
}
.additional-stuff-2 {
  position: relative;
}

.full-screen-icon-custom {
  position: absolute;
  right: 0px;
  top: -18px;
  cursor: pointer;
}
.full-screen .full-screen-icon-custom {
  position: absolute;
  right: 18px;
  top: -18px;
  cursor: pointer;
}

.full-screen-icon-custom.Developer {
  position: absolute !important;
  right: 8px !important;
  top: -18px !important;
  cursor: pointer !important;
}

.full-screen .full-screen-icon-custom.Developer {
  position: absolute !important;
  right: 17px !important;
  top: -18px !important;
  cursor: pointer !important;
}

.full-screen-icon-custom.ActivAdmin {
  position: absolute !important;
  right: 10px !important;
  top: -18px !important;
  cursor: pointer !important;
}

.full-screen .full-screen-icon-custom.ActivAdmin {
  position: absolute !important;
  right: 17px !important;
  top: -18px !important;
  cursor: pointer !important;
}

.video-js .vjs-remaining-time {
  visibility: hidden;
}

.vjs-theme-city .vjs-progress-control .vjs-progress-holder {
  width: 99.5% !important;
}

.full-screen .vjs-theme-city .vjs-progress-control .vjs-progress-holder {
  width: 98.7% !important;
}

.video-js .vjs-time-control {
  display: block;
}
.video-js .vjs-remaining-time {
  display: none;
}
.vjs-theme-city {
  .vjs-time-control,
  .vjs-playback-rate,
  .vjs-playback-rate-value,
  .vjs-fullscreen-control {
    font-size: 14px;
  }
  .vjs-playback-rate {
    order: 3;
    flex: 1 1;
    margin-top: 0.2rem;
  }
}
.vjs-playback-rate-value {
  font-size: 15px;
}
.developer-home {
  .full-screen-icon-custom {
    right: 10px !important;
  }

  // .vjs-menu-button-popup .vjs-menu {
  //   left: 40px;
  // }
  // .vjs-playback-rate > .vjs-menu-button,
  // .vjs-playback-rate .vjs-playback-rate-value {
  //   left: 32px;
  // }
}
.full-screen .normal-screen {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 60px;
  margin-top: -31px;
  background: #f5efef;
}
.normal-screen {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 19px;
  margin-top: -31px;
  background: #f5efef;
  margin-right: 4px;
}
.normal-screen-develpor {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 8px;
  margin-top: -39px;
  background: #f5efef;
}

.vjs-fullscreen-control {
  visibility: hidden;
}
.vjs-loading-spinner {
  z-index: 9999;
}

.vjs-marker {
  display: block !important;
  margin-left: 0 !important;
}
