.leftPanel {
  background-image: url("../../../assets/bgelement.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.warningStyles {
  background-color: rgba(199, 66, 67, 0.15);
  padding: 7px 0px;
  border-radius: 2px;
}
.gradientTextStyles {
  background: linear-gradient(90deg, #f8a0e3, #ffd674);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.textFieldStyles {
  padding-left: 0;
}
