@import "../../../../variables.scss";

.defaultRow {
  width: 100%;
  background: #101112;
}
.hospitalList {
  .hospitalListAccordionHeading {
    padding: 11px 14px;
    span {
      color: $text-color;
    }
  }
  .hospitalListAccordion {
    background: $hospital-card-bg;
    padding: 8px 12px;
    margin-bottom: 8px;
    color: $text-color;
    .bookmarkIcon {
      padding-right: 10px;
      height: 13px;
      width: 13px;
    }
    .buttonIcon {
      font-size: 12px;
      padding-right: 30px;
      height: unset;
      .ms-Button-menuIcon {
        font-size: 12px !important;
      }
    }
    .hospitalListAccordionMain {
      cursor: pointer;
      .hospitalName {
        span {
          color: $light-text-color;
        }
      }
    }
    .hospitalName {
      display: flex;
      justify-content: space-between;
      padding: 10px;
    }
  }

  .accordionHideMainList {
    overflow: hidden;
    height: 0;
    transition: height 0.2s ease;
    &.open {
      // overflow: visible;
      height: auto;
    }
    .accordionHideList {
      padding-top: 9px;
      margin-top: 9px;
      border-top: 1px solid #373737;
    }
    .accordionHideListHeading {
      color: $hospital-list-heading-opacity;
    }
    .accordionHideListSubHeading {
      span {
        color: $hospital-list-heading;
      }
    }
    .accordionHideListText {
      span {
        color: $hospital-list-text;
      }
    }
    .accordionHideAddress {
      color: $text-color;
      line-height: 16px;
    }
  }
}

.answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}
.answer_wrapper.open {
  height: auto;
}

.dataset-name-ellipses {
  max-width: 800px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.disable-opration {
  // pointer-events: none !important;
  height: 30px;
  width: 68px;
  margin-top: 6px;
  background-color: #282828;
  color: white;
  border: none;
  opacity: 0.7;
}
.ms-Button-custom {
  height: 30px;
  width: 68px;
  margin-top: 6px;
  background-color: #01b1ff;
  color: black;
  border: none;
}
