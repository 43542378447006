@import "../../../variables.scss";

.home-img {
  mix-blend-mode: normal;
  opacity: 0.2;
  position: relative;
  width: 100%;
  // img {
  //   // margin-top: 20px;
  //   // position: absolute;
  //   // top: 50%;
  //   // left: 12%;
  //   // transform: translate(-50%, -50%);
  // }
}

.allHospitalsSection {
  margin-top: 24px !important;
  background: $theme-dashboard;
  position: relative;
  .allHospitalsSectionHeading {
    border-top: 1px solid #343434;
    // span {
    //   font-size: 12px;
    //   color: rgba(234, 234, 234, 1);
    //   font-family: "DINPro-Light";
    // }
  }
  .allHospitalsSectionButtons {
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;
    &.space-between {
      justify-content: space-between;
      padding: 0 8px;
    }
    .sortOptionsUserDropdown {
      right: 41px !important;
    }
    .sortOptionsDropdown {
      position: absolute;
      background: #121212;
      top: 39px;
      right: 40px;
      padding: 14px;
      z-index: 1;
      .sortOptionsDropdownHeading {
        color: $text-color;
        border-bottom: 1px solid #2b2b2b;
        padding-bottom: 3px;
      }
      span {
        color: $text-color;
      }
    }
  }
  .layoutShiftButtons {
    padding: 0px 12px;
    border-radius: 2px;
    min-width: 0;
    &.notSelected {
      color: $layout-shift-button-color;
      background: $total-section-bg;
      border: none;
    }
    &.selected {
      border: 1px solid $total-section-bg;
      .ms-Button-flexContainer {
        color: $theme-primary !important;
      }
    }
  }
  .is-active {
    &.searchBox {
      .ms-SearchBox-iconContainer {
        width: 0;
      }
    }
  }
  .searchBox {
    background: transparent;
    border-width: 0;
    border: unset;
    border-color: unset;
    border-bottom: unset;
    width: 168px;
    /* font-family: "DINPro-Regular"; */
    color: #ffffff99;
    .ms-SearchBox-iconContainer {
      width: 101px;
      align-items: flex-end;
    }
    .ms-SearchBox-field {
      margin-left: 5px;
      color: $light-text-color;
    }
    &:hover {
      .ms-SearchBox-iconContainer {
        color: unset;
        border-color: unset;
      }
    }
  }
  .sortDropdown {
    height: 32px;
    margin-right: 13px;
    .sortDropdownHeading {
      color: $light-text-color;
    }
    .sortDropdownIcons {
      padding-left: 7px;
      color: $light-text-color;
    }
    .ms-Button-flexContainer {
      // align-items: left;
      .ms-Button-icon {
        font-size: 12px;
        color: $pure-white;
      }
    }
  }
  .searchBoxSection {
    position: absolute;
    right: 24px;
    top: 6px;
    z-index: 999;
  }
}

.buttonIcon {
  font-size: 14px;
  color: #ffffff99;
  /* background-color: unset; */
  /* padding: 0px 0px; */
  height: 21px;
  &:hover {
    color: #ffffff99;
    // background-color: unset;
    // padding: 0px 0px;
  }
  .ms-Button-menuIcon {
    font-weight: bold !important;
    font-size: 14px !important;
  }
}
.positionRelative {
  position: relative;
}

.modalStyle {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  color: #ffffff;
  background: #000000;
  overflow-y: hidden;
  position: fixed;
}
#userSection {
  .createUserButton {
    cursor: pointer;
    svg {
      width: 12px;
      margin-right: 3px;
      path {
        fill: $active-color;
      }
    }
    span {
      font-size: 12px;
      color: $active-color;
    }
  }
}
.ms-TextField-suffix {
  background-color: $active-color;
  color: rgb(21, 21, 21);
}
.is-selected {
  .ms-Pivot-text {
    color: $text-color;
  }
}
.ms-Pivot-text {
  font-family: DINPro-Medium;
  font-size: 14px;
  color: $dropdown-text;
  font-weight: 400 !important;
}

.currentItemButton-405 {
  color: $text-color;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  color: black !important;
}

.addl-class__input-container {
  color: #ffffff !important;
}
.procedure_select {
  width: 300px;
  top: 8px;
}
