@import "../../../../variables.scss";

.specialityFormHead {
  height: 30px;
  background-color: #1b1b1b;
  border-radius: 2px 2px 0px 0px;
  padding: 0px 10px;
}
.specialityForm {
  background-color: #101112;
  padding: 30px 0px;

  .fieldsContainer {
    padding: 0px 30px;
  }

  .commentSection {
    position: relative;
  }

  .addAnotherFieldBtn {
    margin-top: 10px;
    align-items: center;
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    right: 0px;
    .anotherField {
      margin-right: 6px;
    }

    .addIcon {
      height: 14px;
    }
  }
  .createActiveBtn {
    color: #01b1ff;
    border: 1px solid #0fb3ff;
    box-sizing: border-box;
    border-radius: 2px;
  }

  .createDeleteBtn {
    position: relative;
    top: 9px;
    margin-top: 10px;
  }
}
