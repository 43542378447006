@import "../../../../variables.scss";

.deviceManagement {
  // padding: 30px 100px;
  width: 88%;
  .heading {
    span {
      font-size: 24px;
    }
    padding-bottom: 30px;
  }
  .addDevice {
    padding-bottom: 20px;
    .ms-Button {
      background-color: $active-color;
      border: 1px solid $active-color;
      color: $theme-dashboard;
      font-weight: 600;
      // .ms-Icon {
      // }
    }
  }

  .tableHeading {
    background-color: $theme-dashboard;
    padding: 6px 0px;
    span {
      color: $wizard-text;
    }
  }
  .tableText {
    background-color: #1f1f1f;
    padding: 2px 0px;
    margin-bottom: 1px;
    .ms-StackItem {
      height: auto;
      display: flex;
      align-items: center;
    }
    span {
      color: $dropdown-text;
    }
  }
  .availableDeviceList {
    .deviceSubHeading {
      padding-bottom: 8px;
    }
    padding-top: 20px;
    border-top: 1px solid #343434;
  }
}

@media (min-width: 640px) {
  .ms-lg1-point-5 {
    width: 13.33%;
  }
}

.modelCreation {
  .ms-ChoiceFieldGroup-flexContainer {
    display: flex;
    .ms-ChoiceField {
      margin-right: 50px;
      margin-left: 30px;
      margin-bottom: 20px;
    }
  }
}

.addModel {
  .WizardSectionHeading {
    display: none;
  }
  .createHospitalMain {
    height: calc(100vh - 150px) !important;
  }
}
