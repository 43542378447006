.ms-Panel-main {
  overflow: unset;
  .ms-Panel-scrollableContent {
    overflow: unset;
    overflow-y: auto;
    height: 100%;
  }
}

.modalStyle2 {
  width: 60%;
  max-width: 100%;
  max-height: 100%;

  color: #ffffff;
  background-color: #202022;

  overflow-y: hidden;
  position: fixed;
}

#notification-video-player {
  width: 98%;
}

.userProfile {
  .ms-TextField {
    margin-bottom: 10px;
  }
}
.ms-Panel-main {
  overflow: unset;
  .ms-Panel-scrollableContent {
    overflow: unset;
    overflow-y: auto;
    height: 100%;
  }
}
.modalStyle2 {
  width: 60%;
  max-width: 100%;
  max-height: 100%;
  color: #ffffff;
  background-color: #202022;
  overflow-y: hidden;
  position: fixed;
}
#notification-video-player {
  width: 98%;
}
.userProfile {
  .ms-TextField {
    margin-bottom: 10px;
  }
}
.custom-button-eye {
  height: 30px;
  color: #01b1ff;
  background: #151515;
  border: none;
  cursor: pointer;
}
.custom-labal {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: rgb(191, 195, 196);
  box-sizing: border-box;
  box-shadow: none;
  margin: 0px;
  display: block;
  padding: 5px 0px;
  overflow-wrap: break-word;
  opacity: 0.7;
}
.border-opacity {
  opacity: 0.7;
}
