@import "../../../../variables.scss";

.bulkSurgeonUpload {
  background-color: black;
  .bulkSurgeonUploadSection {
    margin: 3rem 5rem 0.3rem 5rem;
    button {
      font-weight: bold;
      color: aliceblue;
    }

    .bulkUploadSuccess {
      font-family: "HelveticaNeue";
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      line-height: 31px;
      /* identical to box height */

      text-align: center;

      /* Text-gradient */

      background: linear-gradient(107.53deg, #f9a3de 5.72%, #ffd578 100.37%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      // text-fill-color: transparent;
    }

    .uploadIcon {
      color: #0fb3ff;
      font-size: 90px;
    }

    .loader {
      min-height: 0;
      min-width: 0;
    }
  }
}

.roleNameSection {
  padding: 30px 0 10px 0;
  text-align: center;
  span {
    color: $dropdown-text;
  }
  .roleName {
    width: 178px;
    height: 130px;
    background: linear-gradient(
      180deg,
      #313131 2.69%,
      rgba(16, 17, 18, 1) 100%
    );
    border-radius: 4px;
    margin-top: 8px;
    svg {
      height: 36px;
      width: 36px;
      padding-bottom: 10px;
    }
  }
}

// .permissionGroupSection {
//   .algorithmSection {
//     margin: 10px 0px 4px 0px;
//     .ms-Checkbox-text {
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       width: 180px;
//     }
//   }
// }
