$text-color: #ffffffde;
$layout-shift-button-color: #adadae;
$theme-primary: #01b1ff;
$light-text-color: #ffffff99;
$pure-white: #ffffff;
$hospital-list-heading-opacity: rgba(112, 112, 112, 0.87);
$hospital-list-heading: #787878;
$hospital-list-text: rgba(213, 213, 213, 0.6);
$theme-dashboard: #101112;
$wizard-bg: rgb(18, 18, 18);
$grid-bg: #1d1d1d;
$sidebar-bg: #202022;
$wizard-text: #d7d7d7;
$wizard-heading-text: #1b1b1b;
$dropdown-text: #7e7e81;
$active-color: #01b1ff;
$wizard-mute-text: #4a4b4b;
$error-message: rgb(164, 38, 44);
$muted: #605e5c;
$total-section-bg: #242526;
$profile-bg: #636363;
//Hospital Card
$hospital-card-bg: linear-gradient(
  127.05deg,
  rgba(36, 36, 36, 0.8) 8.71%,
  rgba(26, 26, 28, 1) 100%
);
// background: linear-gradient(127.05deg, rgba(36, 36, 36,0.8) 8.71%, rgba(20, 20, 21,0) 100%);
