.rc-video-comparison {
  width: 100%;
  &.full-screen {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  .rc-player {
    width: 100%;
  }
  .play-pause img,
  .mute-volume img {
    padding-right: 20px;
    height: 30px;
    filter: invert(100%);
    -webkit-filter: invert(100%);
  }
  .rc-timer {
    position: relative;
    top: -10px;
  }
  .rc-video-controls {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    background: #000;
    opacity: 0.7;
    color: white;
    .rc-controls {
      display: flex;
      justify-content: space-between;
    }
    .rc-progress {
      background-color: gray;
      height: 25px;
    }
  }
  .video1-dimensions {
    width: 100%;
    height: 80%;
    flex: 1;
  }
  .video2-dimensions {
    width: 100%;
    height: 80%;
    flex: 1;
  }
  .vjs-tech {
    width: 100%;
  }
  .vjs-loading-spinner {
    .vjs-control-text {
      display: none;
    }
  }
}
.comparison-full-screen {
  height: 100vh;
  .full-screen {
    video {
      height: calc(100vh - 132px);
    }
  }
}
.view-details-loader {
  border: 3px solid #f3f3f3;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  z-index: 9999999;
  left: 50%;
  top: 50%;
}

.disabled-previous-icon {
  cursor: not-allowed !important;
  opacity: 0.2;
  pointer-events: none;
}

.disabled-next-icon {
  cursor: not-allowed !important;
  opacity: 0.2;
  pointer-events: none;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  background-color: black;
  border-radius: 6px;
  padding: 5px 0;
  top: -35px;
  left: 0px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.custom-fixed-height-div-compare-video-page {
  min-height: 525px;
}
