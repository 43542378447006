@import "../../../../../../variables.scss";

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  outline: none;
  padding-left: 3px;
  .switch input {
    position: absolute;
    top: -99999px;
    left: -99999px;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 2.6px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  input:checked + .slider {
    background-color: #2196f3;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}

.sectionSubHeading {
  margin-left: 12.5%;
  font-size: 18px !important;
  color: $text-color !important;
  display: block;
  border-bottom: 1px solid #8c8c8c;
  margin-bottom: 13px !important;
  padding-bottom: 10px !important;
}
.root-custom {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  box-sizing: border-box;
  box-shadow: none;
  margin: 0px;
  display: block;
  padding: 5px 0px;
  overflow-wrap: break-word;
}
.fieldGroup-custom {
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border: 1px solid rgb(208, 208, 208);
  border-radius: 2px;
  background: rgb(21, 21, 21);
  cursor: text;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
}
.field-custom {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  margin: 0px;
  padding: 0px 8px;
  box-sizing: border-box;
  border-radius: 0px;
  border: none;
  background: none transparent;
  color: rgb(255, 255, 255);
  width: 100%;
  min-width: 0px;
  text-overflow: ellipsis;
  outline: 0px;
}
.ms-TextField-wrapper-custom {
  padding: 5px 0;
}
.disabled {
  cursor: not-allowed !important;
  background: #282828 !important;
  pointer-events: none !important;
  user-select: none !important;
  opacity: 0.5;
}
.disabled-slider {
  cursor: not-allowed !important;
  background: #282828 !important;
  pointer-events: none !important;
  user-select: none !important;
}
.cursor {
  cursor: pointer;
}
input:disabled {
  cursor: not-allowed !important;
  background: #282828 !important;
}
.display-none {
  display: none;
}
.modalStyle {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  color: #ffffff;
  background: #000000;
  overflow-y: hidden;
  position: fixed;
}
