@import "../../../../variables.scss";

.procedure {
  .heading {
    padding-bottom: 20px;
  }
  .addProcedure {
    span {
      padding-left: 5px;
      color: $active-color;
    }
  }
  .procedureType {
    padding: 10px 37px;
    .addProcedure {
      span {
        margin-right: 5px;
        color: $light-text-color;
      }
    }
  }
  .procedureList {
    .deviceSubHeading {
      margin-bottom: 5px;
    }
  }
  .blukUploadProcedure {
    padding: 15px;
    margin-top: 8px;
    margin-bottom: 25px;
    background-color: $wizard-heading-text;
    .heading {
      color: $dropdown-text;
      font-size: 16px;
    }
    .downloadText {
      font-size: 12px;
      color: $dropdown-text;
    }
    .download {
      i,
      span {
        font-size: 12px;
      }
    }
  }
  .fieldsetInputSection {
    .deleteSection {
      position: absolute;
      top: 1px;
      right: -53px;
    }
  }
  .createHospitalWizardSection .InputSection .ms-Dropdown-container {
    padding: 0;
  }
}

.spacilityModal {
  .smallModalStyle {
    min-height: 250px;
    min-width: 32%;
  }
  .spacialtyForm {
    padding: 30px;
  }
}

.templateUpload {
  color: $dropdown-text;
  padding: 6px 25px;
  border: 0.6px dashed $active-color;
  box-sizing: border-box;
  border-radius: 2px;
  span {
    color: $dropdown-text;
  }
}

.procedureSection {
  border: 1px solid rgba(191, 195, 196, 0.4);
  box-sizing: border-box;
  color: #7e7e81;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
  // border: 1px solid rgb(208, 208, 208);
  border-radius: 2px;
  background: rgb(21, 21, 21);
  cursor: text;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  width: 100%;
  .procedureInput {
    font-size: 14px;
    font-weight: 400;
    box-shadow: none;
    margin: 0px;
    padding: 0px 8px;
    box-sizing: border-box;
    border-radius: 0px;
    border: none;
    background: none transparent;
    color: rgb(255, 255, 255);
    width: 100%;
    min-width: 0px;
    text-overflow: ellipsis;
    outline: 0px;
    &::after {
      border: 1px solid $active-color;
      color: #7e7e81;
    }
    option {
      background-color: #1b1b1b;
      color: #7e7e81;
      padding: 30px !important;
      margin: 20px !important;
      margin-bottom: 20px;
      padding-top: 20px;
    }
  }
}

.wrapper {
  width: 200px;
  padding: 20px;
  height: 150px;
}

.footerSection {
  padding-top: 10px;
}

.ms-mr-20 {
  margin-right: 20px;
}

.ms-mt-20 {
  margin-top: 20px !important;
}

.ProcedureEditModal {
  .smallModalStyle {
    width: 30%;
  }
  .procedureType {
    padding: 16px;
  }
  .btn-c {
    padding-left: 30px;
  }
  .procedureSection1 {
    margin-bottom: 10px;
  }
  .label {
    margin-bottom: 3px;
  }
}
