@import "../../../../../../variables.scss";

.conformation {
  .conformationHeading {
    color: $text-color;
  }
  .conformationMain {
    margin-bottom: 12px;
  }
  .conformationMainTop {
    background-color: $theme-dashboard;
    padding: 20px 20px 0px 20px;
    span {
      color: $dropdown-text;
    }
    .conformationMainLeft {
      .heading {
        color: $wizard-text;
        line-height: 20px;
        padding-bottom: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span {
        padding-bottom: 2px;
      }
    }
    .conformationMainRight {
      .ms-StackItem {
        padding-bottom: 10px;
      }
    }
  }
  .conformationMainBottom {
    margin-top: 3px;
    background-color: $theme-dashboard;
    padding: 20px 20px 0px 20px;
    span {
      color: $dropdown-text;
    }
    .deviceDetails {
      padding: 20px 0px;
      span {
        padding-bottom: 3px;
      }
    }
    .deviceHeading {
      text-align: right;
    }
  }
}

.circle {
  height: 77px;
  width: 77px;
  background-color: $total-section-bg;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $wizard-text;
  // text-transform: uppercase;
}
.circleUser {
  height: 50px;
  width: 50px;
  background-color: #4c4c4c;
  font-size: 22px;
}
.addHospital {
  .show {
    display: block;
    // visibility: visible;
  }
  .hide {
    display: none;
    // visibility: hidden;
  }
}

.conformationMain {
  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }
}

.successMessage {
  height: 100vh;
  .successMessageSection {
    text-align: center;
    width: 27%;
    background: $theme-dashboard;
    border-radius: 0px 10px 10px 0px;
    .successMessagetext {
      padding-bottom: 20px;
      span {
        font-size: 26px;
        line-height: 31px;
        background-image: linear-gradient(
          107.53deg,
          #f9a3de 5.72%,
          #ffd578 100.37%
        );
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
      }
    }
  }
}
