@import "../../../variables.scss";
body {
  overflow-x: inherit !important;
}
.homeMain {
  .homeTopCardSection {
    height: 96px;
  }

  .circle {
    height: 32px;
    width: 74px;
    // background-color: $total-section-bg;
    background-color: $profile-bg;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $wizard-text;
    text-transform: uppercase;
  }

  .home-img {
    mix-blend-mode: normal;
    opacity: 0.2;
    position: relative;
    width: 100%;
    // img {
    //   // margin-top: 20px;
    //   // position: absolute;
    //   // top: 50%;
    //   // left: 12%;
    //   // transform: translate(-50%, -50%);
    // }
  }

  .allHospitalsSection {
    margin-top: 24px !important;
    background: $theme-dashboard;
    position: relative;
    .allHospitalsSectionHeading {
      border-bottom: 1px solid #343434;
      span {
        font-size: 18px;
        color: rgba(234, 234, 234, 1);
        font-family: "DINPro-Light";
      }
    }
    .allSectionButtons {
      display: flex;
      justify-content: flex-end;
      &.space-between {
        justify-content: space-between;
        padding: 0 8px;
      }
      .sortOptionsUserDropdown {
        right: 41px !important;
      }
    }
    .layoutShiftButtons {
      padding: 0px 12px;
      border-radius: 2px;
      min-width: 0;
      &.notSelected {
        color: $layout-shift-button-color;
        background: $total-section-bg;
        border: none;
      }
      &.selected {
        border: 1px solid $total-section-bg;
        .ms-Button-flexContainer {
          color: $theme-primary !important;
        }
      }
    }
    .is-active {
      &.searchBox {
        .ms-SearchBox-iconContainer {
          width: 0;
        }
      }
    }
    .searchBox {
      background: transparent;
      border-width: 0;
      border: unset;
      border-color: unset;
      border-bottom: unset;
      width: 168px;
      /* font-family: "DINPro-Regular"; */
      color: #ffffff99;
      .ms-SearchBox-iconContainer {
        width: 107px;
        align-items: flex-end;
      }
      .ms-SearchBox-field {
        margin-left: 5px;
        color: $light-text-color;
      }
      &:hover {
        .ms-SearchBox-iconContainer {
          color: unset;
          border-color: unset;
        }
      }
    }
    .sortAction {
      height: 32px;
      margin-right: 13px;
      .sortDropdownHeading {
        color: $light-text-color;
      }
      .sortDropdownIcons {
        padding-left: 7px;
        color: $light-text-color;
      }
      .ms-Button-flexContainer {
        // flex-direction: row-reverse;
        .ms-Button-icon {
          font-size: 12px;
          color: $pure-white;
        }
      }
    }
  }

  .buttonIcon {
    font-size: 14px;
    color: #ffffff99;
    /* background-color: unset; */
    /* padding: 0px 0px; */
    height: 21px;
    &:hover {
      color: #ffffff99;
      // background-color: unset;
      // padding: 0px 0px;
    }
    .ms-Button-menuIcon {
      font-weight: bold !important;
      font-size: 14px !important;
    }
  }
  .positionRelative {
    position: relative;
  }

  .onBoard {
    margin-left: -23px;
  }

  .thirdGrid {
    background: linear-gradient(
      270deg,
      rgba(175, 135, 14, 0.847) 36.03%,
      rgba(74, 63, 31, 0.847) 100%
    );
  }
  .thirdGridWidth {
    width: 360px;
  }

  .textData {
    font-size: 32px;
  }

  .firstGrid {
    padding: 0;
    background: linear-gradient(270deg, #1d3441 -0.81%, #2f5b6f 100%);
  }

  .text1 {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  .text2 {
    width: 181px;
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: rgb(196, 193, 193);
  }
  .text3 {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    cursor: pointer;
    /* Activ-color */

    color: #0fb3ff;
  }
  .onboardButton {
    //     position: absolute;
    width: 201px;
    height: 65px;
    cursor: pointer;
    // left: 154px;
    // top: 88px;

    /* Activ-color */

    background: #0fb3ff;
    border-radius: 2px;
  }
  .modalStyle {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    color: #ffffff;
    background: #000000;
    overflow-y: hidden;
    position: fixed;
  }

  .surgeonCardWrapper > div {
    justify-content: flex-start;
  }
  .allVideoStackContainer > div {
    justify-content: flex-start;
  }
  .surgeonCard {
    background: #1d1d1d;
    width: 266px;
    height: 110px;
    position: relative;
    word-wrap: break-word;

    .buttonIcon {
      position: absolute;
      top: 12px;
      right: 12px;
      color: #686868;
    }

    .surgeonCardTitle {
      color: #ffffff99;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: break-word;
      width: 78%;
      overflow: hidden;
    }

    abbr {
      text-decoration: none !important;
    }
    .surgeonCardDetails {
      color: #686868;
      font-size: 10px;
      > div {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .surgeonCardDate {
      position: absolute;
      bottom: 12px;
      right: 12px;
      color: #686868;
    }
  }

  .allVideosVideoCard {
    width: 250px;
    min-height: 230px;
  }

  .homeBottomCardSection {
    position: relative;

    .width-100-percentage {
      width: 100%;
    }

    .filterOptions {
      position: absolute;
      right: 12px;
      top: 12px;

      .is-active {
        &.searchBox {
          .ms-SearchBox-iconContainer {
            width: 0;
          }
        }
      }
      .searchBox {
        background: transparent;
        border-width: 0;
        border: unset;
        border-color: unset;
        border-bottom: unset;
        color: #ffffff99;
        width: 93px;
        height: 32px;
        .ms-SearchBox-field {
          margin-left: 5px;
          color: $light-text-color;
        }
        &:hover {
          .ms-SearchBox-iconContainer {
            color: unset;
            border-color: unset;
          }
        }
      }

      .layoutShiftButtons {
        padding: 0px 12px;
        border-radius: 2px;
        min-width: 0;
        &.notSelected {
          color: $layout-shift-button-color;
          background: $total-section-bg;
          border: none;
        }
        &.selected {
          border: 1px solid $total-section-bg;
          .ms-Button-flexContainer {
            color: $theme-primary !important;
          }
        }
      }
    }
  }
}
.createUserButton {
  cursor: pointer;
  svg {
    width: 12px;
    margin-right: 3px;
    path {
      fill: $active-color;
    }
  }
  span {
    font-size: 12px;
    color: $active-color;
  }
}

.sortOptionsDropdown {
  position: absolute;
  background: #121212 !important;
  top: 39px;
  right: -12px;
  z-index: 1;
  padding: 14px;
  .sortOptionsDropdownHeading {
    color: $text-color;
    border-bottom: 1px solid #2b2b2b;
    padding-bottom: 3px;
  }
  span {
    color: $text-color;
  }
}

#sortButton {
  display: flex !important;
  justify-content: space-between !important;
  height: 32px !important;
}
.no-data-img {
  width: 90%;
  margin-top: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #7e7e81;
}
