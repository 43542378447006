.scroll::-webkit-scrollbar {
  width: 8px;
  background: #171717;
}

.scroll::-webkit-scrollbar-track {
  background-clip: content-box;
  border-radius: 15px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #35373f;
}

.modalStyle {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;

  color: #ffffff;
  background-color: #000000;

  overflow-y: hidden;
  position: fixed;
}

.toolTip_design {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem 1.5rem 1rem;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px #0000001f,
    0 5px 5px -3px rgba(0, 0, 0, 0.2);
  background-color: #2e2e2f;

  .toolTip_uploader_name {
    @font-face {
      font-family: "HelveticaNeue-Medium";
      src: url("../../../fonts/HelveticaNeue-Medium.ttf") format("truetype");
    }
    color: #d8d8d8;
    font-size: 14px;
    height: 20px;
    width: 135px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 20px;
  }
  .toolTip_upload_details {
    @font-face {
      font-family: "HelveticaNeue-Medium";
      src: url("../../../fonts/HelveticaNeue-Bold.ttf") format("truetype");
    }
    height: 10px;
    width: 135px;
    color: #a9a9a9;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
  }
}
#chart {
  max-width: 650px;
}

@media screen and (max-width: 1024px) {
  .fl-layout {
    gap: 0px 30px;

    .ms-StackItem {
      &:last-child {
        margin-left: 0px;
      }

      .css-257 {
        max-width: 320px;
      }
    }
  }
}
.textEllipses_procedures {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 876px) {
  .fl-layout {
    gap: 0px 15px;

    .ms-StackItem {
      .css-257 {
        max-width: 240px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .fl-layout {
    gap: 30px 0px;
    flex-direction: column;
    margin-bottom: 60px;
    .ms-StackItem {
      .css-257 {
        max-width: 100%;
      }
    }
  }
}
.apexcharts-xaxis-annotations line {
  stroke: transparent;
}

.parentGrid .childGrid {
  display: flex;
  flex-wrap: wrap;
}

.textEclips {
  width: 500px;
  max-width: 500px;
  overflow: hidden;
}
.textEclipsChart {
  width: 200px;
  max-width: 500px;
  overflow: hidden;
}
