@import "../../../../variables.scss";

@media (min-width: 640px) {
  .Workflow {
    .workflowMap {
      margin-left: 20px !important;
    }
    .ms-lg2-point-5 {
      width: 22.66%;
    }
    .ms-lg5-point-0 {
      width: 45.32%;
    }
    .workflowForm {
      margin-top: 30px;
      height: calc(100vh - 200px);
      padding-bottom: 30px;
      overflow-y: overlay;
      .algDropdown {
        padding-top: 5px;
      }
    }
  }
}
.algorithmSequence {
  padding: 20px;
}

.defaultButton {
  background-color: transparent !important;
  color: #01b1ff !important;
}

.workflowSection {
  padding-right: 39px !important;
}

.ms-Dropdown-optionText {
  color: #ffffff;
}

// .workflowInput {
//   &.ms-StackItem {
//     padding-top: 5px;
//   }
// }
.textEllipses_create {
  span {
    position: absolute;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0px;
  }
}
