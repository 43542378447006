.parentGrid .childGrid {
  display: flex;
  flex-wrap: wrap;
}

.uploadparentGrid .uploadchildGrid {
  display: flex;
  flex-wrap: wrap;
}

.modalStyle1 {
  width: 40%;
  height: 70%;
  max-width: 100%;
  max-height: 100%;
  color: #ffffff;
  background-color: #202022;

  overflow-y: hidden;
  position: fixed;
}

.ms-SelectionZone {
  display: flex;
  justify-content: space-between;
}
.ms-PickerPersona-container {
  flex: 1;
  border-radius: 0%;
  padding: 0.2rem;
  margin: 0.5rem;
}

.stepBar {
  float: left;
  padding: 10px 30px 10px 40px;
  background: #1d1d1d;
  position: relative;
  border-top: 1px solid #1d1d1d;
  border-bottom: 1px solid #1d1d1d;
  width: 10%;
  height: 1.5rem;
  margin: 0 2px;
  text-align: left;
}

.stepBar :before {
  content: "";
  border-top: 22px solid #fff;
  border-bottom: 22px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}
.stepBar1 :before {
  content: "";
  border-left: 22px solid #000000;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.stepBar :after {
  content: "";
  border-left: 22px solid #1d1d1d;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 20;
}

.play_button:hover {
  height: 20%;
  width: 20%;
}
.custom-footer {
  position: fixed !important;
  width: 100% !important;
  bottom: 0;
}
