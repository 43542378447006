@import "../../../../../../variables.scss";

.addDevices {
  .addADevice {
    padding: 5px 0;
    span {
      color: $dropdown-text;
    }
    .addADeviceMain {
      span {
        padding: 0 5px;
        color: $active-color;
      }
    }
  }
  .toggleHideBg {
    background-color: $theme-dashboard;
    padding: 20px 20px !important;
  }
  .addDeviceHeading {
    .ms-StackItem {
      padding-bottom: 4px;
      span {
        color: $light-text-color;
      }
    }
    .label {
      padding-left: 8px;
    }
  }
  .addDeviceSection {
    span {
      color: $dropdown-text;
    }
    .ms-TextField-fieldGroup,
    .ms-Dropdown-title {
      border: 1px solid rgba(191, 195, 196, 0.4);
      box-sizing: border-box;
      color: $dropdown-text;
    }
  }
}

@media (min-width: 640px) {
  .ms-lg2-point-5 {
    width: 18.19%;
  }
}

.addDeviceItem {
  .ms-TextField-fieldGroup,
  .ms-Dropdown-title,
  .css-b62m3t-container {
    border: 1px solid rgba(191, 195, 196, 0.4);
    box-sizing: border-box;
    color: $dropdown-text;
  }
  .addl-class__control {
    background-color: rgb(21, 21, 21);
    border-color: rgb(21, 21, 21);
    height: 30px;
    min-height: 33px;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      height: 30px;
      min-height: 33px;
    }
  }
  .addl-class__menu-list {
    background-color: rgb(21, 21, 21);
  }
  .addl-class__option {
    &:hover,
    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      background-color: #333333;
      border-color: #333333;
    }
  }
  .css-1okebmr-indicatorSeparator {
    -webkit-align-self: unset;
  }
  .css-1f43avz-a11yText-A11yText {
    padding: 5px;
  }
  .css-14el2xx-placeholder {
    margin-bottom: 3px;
  }
  .css-qc6sy-singleValue {
    color: #ffffff;
  }
  .css-319lph-ValueContainer {
    padding: 2px 5px;
  }
}
