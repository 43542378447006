.container1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1rem;
  max-width: 100%;

  .surgery_break_up_bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 1rem 0.5rem 1rem;
  }
  .surgery_break_up_legend {
    display: flex;
    margin: 1rem 1rem 0.5rem 1rem;
    justify-content: space-between;
    max-width: 81.7vw;
    overflow-x: auto;
    padding-bottom: 1rem;
  }
  .surgery_break_up_legend::-webkit-scrollbar {
    width: 7px;
    border-radius: 15px;
    background: #121212;
  }

  .surgery_break_up_legend::-webkit-scrollbar-track {
    background-clip: content-box;
    border-radius: 15px;
  }

  .surgery_break_up_legend::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: #35373f;
  }
}

.videoCommentsSection {
  max-height: 500px;
  overflow: auto;
  .timeinterval-font {
    font-size: 14px;
    font-weight: 400;
    text-align: end;
    label {
      text-align: start;
      font-size: 12px;
      font-weight: 400;
      padding: 2px;
    }
    .ms-TextField-fieldGroup {
      height: 26px;
      width: 76px;
      margin-right: 10px;
    }
  }

  .ms-TextField--underlined {
    .ms-TextField-fieldGroup {
      border: none;
      margin-bottom: 0px;
      color: "#fff";
      padding-right: 28px;
    }
  }
}
