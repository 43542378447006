@import "../../../../variables.scss";

.createHospital {
  background-color: black;
  .createHospitalSection {
    margin: 3rem 5rem 0.3rem 5rem;
    button {
      font-weight: bold;
      color: aliceblue;
    }
  }
  .createHospitalHeading {
    span {
      font-family: "HelveticaNeue-Medium";
      font-weight: 200;
    }
  }
  .createHospitalMain {
    margin: 0 5rem;
    .wizardHeight {
      height: calc(100vh - (72px + 136px));
      .addFacility {
        padding: 15px 0 5px 0;
        .ms-Toggle {
          margin: 0;
        }
        .addFacilityHeading {
          padding-right: 10px;
        }
      }
      .addAnotherFacility {
        padding: 10px 0 40px 0;
        span {
          padding-right: 6px;
          color: #01b1ff;
        }
      }
    }
  }
  .createHospitalWizard {
    background-color: $theme-dashboard;
    .circleNumber {
      margin: 0.5rem;
      border-radius: 50%;
      border: 1.5px solid #4a4b4b;
      text-align: center;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .step-completed {
      border-color: #099a34;

      span {
        color: #099a34 !important;
      }
    }
    .completed {
      margin: 0.5rem;
      .iconCompleted {
        color: #6bb700;
      }
    }
    .circleNumber_one {
      margin: 0.5rem;
      border-radius: 50%;
      border: 1.5px solid rgb(1, 177, 255);
      color: rgb(1, 177, 255) !important;
      text-align: center;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .circleNumber_two {
      margin: 0.5rem;
      border-radius: 50%;
      border: 1.5px solid #099a34;
      color: #099a34 !important;
      text-align: center;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .span {
      color: $wizard-text !important;
    }
    .activeSidebar {
      background-color: $grid-bg !important;
      &::after {
        border-left: 22px solid $grid-bg !important;
      }
      .circleNumber {
        border: 1.5px solid $wizard-text;
      }
      span {
        color: $wizard-text !important;
      }
      &.sidebar1 {
        &::before {
          border-left: 22px solid $grid-bg;
        }
      }
    }
    .stepBar {
      float: left;
      // padding: 10px 30px 10px 10px;
      padding: 10px 50px 7px 10px;
      position: relative;
      height: 25.5px;
      text-align: left;
      background-color: $theme-dashboard;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      span {
        font-family: "HelveticaNeue-Medium";
        color: $wizard-mute-text;
      }
      &::before {
        content: "";
        border-top: 22px solid #fff;
        border-bottom: 22px solid #fff;
        position: absolute;
        top: 0;
        left: 0;
      }
      &::after {
        content: "";
        border-left: 22px solid $theme-dashboard;
        border-top: 22px solid transparent;
        border-bottom: 21px solid transparent;
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 20;
      }
    }
    .stepBar1 {
      padding: 10px 30px 10px 36px;
      &::before {
        content: "";
        border-left: 25px solid $grid-bg;
        border-top: 23px solid transparent;
        border-bottom: 21px solid transparent;
        position: absolute;
        top: 0;
        left: 0;
      }
      //   &::after {
      //     content: "";
      //     position: absolute;
      //     left: 0;
      //     bottom: 0;
      //     width: 0;
      //     height: 0;
      //     border-left: 20px solid white;
      //     border-top: 20px solid transparent;
      //     border-bottom: 20px solid transparent;
      //   }
    }
  }
}
.createHospitalWizardSection {
  // background-color: $wizard-bg;
  margin-top: 10px;
  .ms-lgOffset1 {
    margin-left: 8.9%;
  }
  .WizardSectionHeading {
    background-color: $wizard-heading-text;
    padding: 7px 20px;
    cursor: pointer;
    .buttonIcon {
      margin-right: 15px;
    }
  }
  .open {
    display: block;
  }
  .close {
    display: none;
  }
  .fieldsetInputSection {
    border: 0;
    margin: 0;
    padding: 0;
  }
  .InputSection,
  .addDevices,
  .deviceInputSection,
  .permissionGroupSection {
    background-color: $theme-dashboard;
    padding: 10px;
    margin-top: 0;

    label {
      color: $light-text-color;
      // font-family: "HelveticaNeue-Regular";
      font-size: 16px;
      font-weight: normal;
    }
    // .firstSection {
    //   padding-top: 21px;
    // }
    .ms-TextField,
    .ms-Dropdown-container {
      padding: 5px 0;
    }
    .sectionHeading {
      //color:white;
      padding: 20px 0;
      color: rgba(234, 234, 234, 1);
      font-family: "DINPro-Medium";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
    }
    .ms-TextField-fieldGroup,
    .ms-Dropdown-title,
    .css-b62m3t-container {
      border: 1px solid rgba(191, 195, 196, 0.4);
      box-sizing: border-box;
      color: $dropdown-text;
    }
    .addl-class__control {
      background-color: rgb(21, 21, 21);
      border-color: rgb(21, 21, 21);
      height: 30px;
      min-height: 33px;
      &:hover,
      &:before,
      &:active,
      &:focus,
      &:focus-visible,
      &:focus-within {
        background-color: rgb(21, 21, 21);
        height: 30px;
        min-height: 33px;
      }
    }
    .addl-class__menu-list {
      background-color: rgb(21, 21, 21);
    }
    .addl-class__option {
      background-color: rgb(21, 21, 21);
      &:hover,
      &:active,
      &:focus,
      &:focus-visible,
      &:focus-within {
        background-color: #333333;
        border-color: #333333;
      }
    }
    .css-1okebmr-indicatorSeparator {
      -webkit-align-self: unset;
      align-self: unset;
    }
    .css-1f43avz-a11yText-A11yText {
      padding: 5px;
    }
    .css-14el2xx-placeholder {
      margin-bottom: 3px;
    }
    .css-qc6sy-singleValue {
      color: #ffffff;
    }
    .css-319lph-ValueContainer {
      padding: 2px 5px;
    }
  }
}
.createHospitalWizardFooter {
  z-index: 9999;
  padding: 0.8rem 3rem;
  position: fixed;
  bottom: 0;
  width: 100.5%;
  background-color: $sidebar-bg;
  .nextCancel {
    button {
      margin-right: 46px;
      padding: 16px;
      font-weight: bolder;
    }
  }
  .btn-back {
    margin-left: 46px;
  }
  .btn-c {
    color: $active-color;
    font-weight: 500;
  }
  .btn-next {
    span {
      &.ms-Button-label {
        padding: 16px;
        font-weight: bolder;
      }
    }
  }
}

.modalStyle {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  color: #ffffff;
  background: #000000;
  overflow-y: hidden;
  position: fixed;
}

.errorMessage,
.ms-TextField-errorMessage span {
  font-size: 12px !important;
  font-weight: 400;
  color: $error-message !important;
}

.fieldsetInputSection {
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
}

.btn-c {
  color: $active-color;
  font-weight: 500;
}

.ms-DatePicker {
  button {
    color: $text-color;
  }
}
