@import "../../../../variables.scss";

#userCard {
  border-radius: 2px;
  margin: 8px 0;
  height: 80px;
  .circle {
    height: 32px;
    width: 32px;
    background-color: $profile-bg;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $wizard-text;
    text-transform: uppercase;
  }
  .userCardTextSection {
    background-color: $grid-bg;
    padding: 7px;
    .userTextSection {
      max-width: 171px;
      margin-left: 6px;
      .userName {
        padding-bottom: 3px;
        font-size: 14px;
      }
    }
    .userText {
      color: $dropdown-text;
      font-size: 10px;
    }
  }
}

.userCreate {
  height: calc(100vh - (72px + 36px));
  margin-top: 10px;
  .userCreateFormSection2 {
    padding-top: 8px;
  }
  .hide {
    visibility: hidden;
  }
  .show {
    visibility: visible;
  }
  .label {
    padding-bottom: 6px;
    padding-top: 5px;
  }
}
