.nav-cls {
  width: auto;

  .nav-item {
    padding: 0.5rem;
    .nav-text {
      font-size: 10px;
      color: #a8a8a8;
      line-height: 10px !important;
      white-space: normal;
    }
    .nav-text:hover {
      color: #ffffff;
    }
  }
  a:hover {
    color: #ffffff;
  }
  a {
    line-height: 0rem;
    height: 4.5rem;
    padding: 0px;
    span {
      justify-content: center;
    }
  }
  a::after {
    border-left: 3px solid;
    border-left: 3px solid;
    border-image: linear-gradient(#0051b0, #fbaffc) 0 100%;
    // border-color: #0fb3ff;
  }
  button:hover {
    color: #ffffff;
  }
  button {
    line-height: 0rem;
    height: 4.5rem;
    padding: 1rem;
  }
  button::after {
    border-left: 3px solid;
    border-color: #0fb3ff;
  }
}
