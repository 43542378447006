@import "../../../../variables.scss";

@mixin hopsitalCardSection($flex: 1) {
  text-align: start;
  flex: $flex;
  font-size: 10px;
}
@mixin textStyle($font, $clr) {
  font-size: $font;
  color: $clr;
}
#hospitalCard {
  max-height: 298px !important;
  background: $hospital-card-bg;
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  span {
    text-align: start;
  }

  .hospitalCardDeviceInfo {
    span {
      @include textStyle($font: 9px, $clr: rgba(213, 213, 213, 0.6));
    }
    height: 20px;
  }
  .hospitalCardDeviceInfoEmpty {
    padding: 10px 0px;
  }
  .hospitalCardHeading {
    @include textStyle($font: 10px, $clr: #a8a8a8);
    width: 90px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .hospitalCardText {
    @include textStyle($font: 12px, $clr: #d7d7d7);
    padding-top: 3.5px;
    line-height: 14px;
  }
  .hospitalCardName {
    @include textStyle($font: 14px, $clr: #d2d2d2);
    font-family: HelveticaNeue-Medium;
  }
  .hospitalCardSection1 {
    text-align: start;
    padding-bottom: 28px;
  }
  .hospitalCardIcon {
    padding-right: 4px;
    text-align: end;
    flex: 1;
  }
  .ms-pr-0 {
    padding-right: 0;
  }

  .ms-bb-0 {
    text-decoration: unset;
  }
}
