@import "../../../../variables.scss";

#videoCard {
  background-color: $grid-bg;
  margin-bottom: 8px;
  margin-top: 8px;
  .videoCardImageSection {
    cursor: pointer;
    position: relative;
    .duration {
      bottom: 0.1rem;
      position: absolute;
      left: 0.5rem;
      padding: 0.2rem 0.2rem 0.2rem 0;
      font-size: 12px;
    }
    .playIcon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      img {
        filter: brightness(1.9) saturate(120%);
        opacity: 1;
      }
    }
  }
  .videoCardTextSection {
    padding: 7px;
    .cardHeading {
      font-size: 14px;
    }
  }
  .videoCardFooter {
    span {
      color: $dropdown-text;
      padding: 6px 0;
      font-size: 10px;
    }
  }
}
