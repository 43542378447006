@import "../../../variables.scss";
.managementSubLinks {
  width: 159px;
  min-height: calc(100vh - (52px + 1px));
  max-height: 100%;
  padding-left: 10px;
  padding-top: 15px;
  position: fixed;
  //   min-width: 100vh;
  //   max-width: 100%;
  .ms-Button {
    padding: 0 0 0 5px;
    display: flex;
    align-items: center;
    justify-self: center;
  }
  .ms-Button-flexContainer {
    width: 100%;
  }
  .nav-item {
    width: 100%;
    .navIcon {
      width: 25px;
    }
    .navText {
      color: $dropdown-text;
    }
  }
}
