@import "../../variables.scss";

.imageIcon {
  height: 40px;
  width: 40px;
  background-color: $total-section-bg;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $wizard-text;
  text-transform: uppercase;
}
.video-commenting-page-comment-section .ms-ContextualMenu-Callout {
  right: 48px !important;
  width: 100px !important;
}
.custom-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #ffffff21;
}
.view-details-loader {
  border: 3px solid #f3f3f3;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: 50%;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.custom-unshare-icon {
  border-left: 2px solid #deebde;
  position: absolute;
  left: 23%;
  top: 8px;
  height: 16px;
  transform: rotate(130deg);
  z-index: 1;
}
.custom-pivot {
  //text-align: center;
  // width: 310px;
  justify-content: space-around;
}

.custom-pivot div button {
  width: 130px;
}

.custom-pivot .videoCommentsSection div div button {
  display: none;
}
.custom-pivot .videoCommentsSection div div button {
  display: none;
}

.custom-pivot .videoCommentsSection button.test-234 {
  display: block !important;
  width: auto;
}
.custom-pivot .videoCommentsSection button.test-123 {
  display: block !important;
  width: auto;
}
.custom-pivot .videoCommentsSection {
  max-height: 750px;
}
.scroll-properties-regular-comments {
  max-height: 354px;
  overflow: auto;
  height: 354px;
}
// .scroll-properties-insight-comments {
//   max-height: 636px;
//   overflow: auto;
//   height: 636px;
// }

.scroll-properties-insight-comments {
  max-height: 552px;
  overflow: auto;
  height: 552px;
}
.custm-anchor-time-frame {
  color: rgb(1, 177, 255);
  cursor: pointer;
  text-decoration: none;
}
.custm-anchor-time-frame:hover {
  text-decoration: underline;
}
