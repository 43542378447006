@import "../../../../variables.scss";

.homeTopCardSection {
  color: $text-color;
  .totalCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 17px;
    .totalCardCenter {
      display: flex;
      align-items: center;
      font-size: 12px;
      .totalCardCenterText {
        font-size: 14px;
        // font-family: "DINPro-Light";
        text-align: end;
        padding-right: 8px;
      }
      .totalCardCenterCount {
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #162526;
        font-size: 80px;
        font-family: "DINPro-Light";
      }
    }
    .totalCardRight {
      text-align: right;
      span {
        font-size: 12px;
        // font-family: "DINPro-Light";
        padding-bottom: 8px;
        color: $hospital-list-text;
      }
    }
  }
  .createHospitalCard {
    background: linear-gradient(90deg, #0083fa 0%, #012e73 100%);
    border-radius: 2px;
    opacity: 0.2;
    display: flex;
    justify-content: flex-end;
    width: 202px !important;
    img {
      transition: transform 0.6s;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  .totalHospitals {
    background: linear-gradient(
      89.98deg,
      rgba(147, 247, 252, 0.3) 0.02%,
      rgba(147, 247, 252, 0.1) 99.98%
    );
    border-radius: 2px;
  }
  .totalDevices {
    background: linear-gradient(
      89.94deg,
      rgba(138, 200, 254, 0.3) 0.05%,
      rgba(138, 200, 254, 0.1) 99.94%
    );
    border-radius: 2px;
  }
  .HospitalCardTextOverlay {
    position: absolute;
    left: 23%;
    top: 50%;
    transform: translate(-7%, -50%);
    display: flex;
    span {
      padding-left: 8px;
      // font-family: "DINPro-Regular";
      font-size: 16px;
      line-height: 1;
      align-items: center;
    }
  }
}
