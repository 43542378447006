@import "../../../../variables.scss";
.algorithm {
  margin-top: 30px;
  height: calc(100vh - 200px);
  overflow-y: auto;
  .ms-TextField-fieldGroup,
  .ms-Dropdown-title,
  .css-b62m3t-container {
    border: 1px solid rgba(191, 195, 196, 0.4);
    box-sizing: border-box;
    color: $dropdown-text;
    margin-bottom: 10px;
  }
  .addl-class__control {
    background-color: rgb(21, 21, 21);
    border-color: rgb(21, 21, 21);
    height: 30px;
    min-height: 33px;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      height: 30px;
      min-height: 33px;
    }
  }
  .addl-class__menu-list {
    background-color: rgb(21, 21, 21);
  }
  .addl-class__option {
    &:hover,
    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      background-color: #333333;
      border-color: #333333;
    }
  }
  .css-1okebmr-indicatorSeparator {
    -webkit-align-self: unset;
  }
  .css-1f43avz-a11yText-A11yText {
    padding: 5px;
  }
  .css-14el2xx-placeholder {
    margin-bottom: 3px;
  }
  .css-qc6sy-singleValue {
    color: #ffffff;
  }
  .css-319lph-ValueContainer {
    padding: 2px 5px;
  }
  .addProcedure {
    span {
      margin-right: 5px;
      color: #01b1ff;
    }
    svg {
      padding-left: 5px;
    }
  }
}
.fieldsetInputSection {
  .deleteSection {
    position: absolute;
    top: 1px;
    right: -50px;
  }
}
.textEllipses_name {
  span {
    position: absolute;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 18px;
  }
}
.warning-dialog {
  .ms-Dialog-main {
    padding: 15px;
    width: 250px;
    .pb-20 {
      padding-bottom: 20px;
    }
    .btn-proceed {
      padding-left: 15px;
    }
  }
}
.field-custom {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  margin: 0px;
  padding: 0px 8px;
  box-sizing: border-box;
  border-radius: 0px;
  border: none;
  background: none transparent;
  color: rgb(255, 255, 255);
  width: 100%;
  min-width: 0px;
  text-overflow: ellipsis;
  outline: 0px;
}
.fieldGroup-custom {
  box-shadow: none;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border: 1px solid rgb(208, 208, 208);
  border-radius: 2px;
  background: rgb(21, 21, 21);
  cursor: text;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
}
